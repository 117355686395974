<template>
  <section class="chat">
    <div
      ref="chat"
      class="overflow-y-scroll chat__messages"
      @scroll="scrollHandlerThrottle"
    >
      <div
        v-show="!loaded"
        ref="vldParent"
        class="vld-parent"
      />
      <list-messages
        v-show="areMessages || areSms"
        class="p-2"
        :messages="areMessages ? messages.items : sms.items"
        :loading="!loaded"
        :approve="areMessages ? profile.approve : null"
        :is-approve-menu-disabled="isMessageEditMode"
        :messages-group="activeMessagesGroup"
        @edit-approval="editApproval"
        @delete-message="updateMessagesOrEmails('messages', $event, true)"
        @edit-message="enterEditMessageMode"
        @update:approve="$emit('refetch-info')"
      />
      <list-emails
        v-show="areEmails"
        :show-send-email-btn="!(areEmails && showBlocks.emailResponserClosable)"
        data-excluded="emailResponserClosable"
        class="pr-2 pl-3 pt-2 pb-2"
        :emails="emails.items"
        :loading="!loaded"
        :profile="profile"
        @reply="replyToEmail"
      />

      <keep-alive>
        <tasks-list
          v-if="areTasks && loaded"
          :profile-id="profile._id"
          :expanded-task-id="expandedTaskId"
          @update:active-messages-group="$emit('update:active-messages-group', $event)"
          @close-inbox-modal="$emit('close-inbox-modal')"
        />

        <activity-list
          v-if="isActivity && loaded"
          :profile-id="profile._id"
        />
      </keep-alive>
    </div>

    <div class="pt-05 pl-05 pr-05 pb-05">
      <messages-responser
        v-show="areMessages && isSocialFeatureAvailable"
        class="ml-2 mr-2 mb-2 box-shadow_s"
        :is-edit-mode="isMessageEditMode"
        :message-to-edit="messageToEdit"
        :profile="profile"
        :is-disabled="isDeclinedProfile"
        :show-sender="showSender"
        @send="updateMessagesOrEmails('messages', $event)"
        @update-message="updateApproveMessage"
        @exit-edit-mode="exitEditMode"
      />
      <emails-responser
        v-show="areEmails && showBlocks.emailResponserClosable"
        :last-email="lastEmail"
        :profile="profile"
        class="chat__emails-responser box-shadow_s"
        data-closable="emailResponserClosable"
        @send-email="sendEmailHandler"
        @close="showBlocks.emailResponserClosable = false"
      />
      <sms-responser
        v-show="areSms && isSmsFeatureAvailable"
        class="ml-2 mr-2 mb-2 box-shadow_s"
        :profile="profile"
        :is-disabled="isDeclinedProfile"
        @send="updateMessagesOrEmails('sms', $event)"
      />
    </div>
  </section>
</template>
<script>
  import { nextTick } from 'vue';
  import ListMessages from '@/components/inboxPage/chat/messages/ListMessages.vue';
  import ListEmails from '@/components/inboxPage/chat/emails/ListEmails.vue';
  import TasksList from '@/components/inboxPage/chat/tasks/TasksList.vue';
  import ActivityList from '@/components/inboxPage/chat/activity/ActivityList.vue';
  import MessagesResponser from '@/components/inboxPage/chat/messages/responser/MessagesResponser.vue';
  import EmailsResponser from '@/components/inboxPage/chat/emails/responser/EmailsResponser.vue';

  import { closableMixin } from '@/mixins/closableMixin';
  import { differentFunctionsMixin } from '@/mixins/differentFunctionsMixin';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { throttle } from 'lodash';
  import { mapGetters } from 'vuex';
  import {
    getMessages,
    getEmails,
    getSms
  } from '@/api/profilesMethods';
  import { updateApproval } from '@/api/approvalMethods';
  import SmsResponser from '@/components/inboxPage/chat/sms/responser/SmsResponser.vue';
  import { updateTaskApprovedMessage } from '@/api/dashboardMethods';
  import { tierFeatures } from '@/data/tiersFeatures';

  export default {
    name: 'ChatInbox',
    components: {
      SmsResponser,
      ListMessages,
      ListEmails,
      TasksList,
      ActivityList,
      MessagesResponser,
      EmailsResponser,
    },
    mixins: [closableMixin, loaderMixin, differentFunctionsMixin],
    props: {
      activeMessagesGroup: {
        type: String,
        required: true,
      },
      hasOldMessagesButton: {
        type: Boolean,
        required: true,
      },
      profile: {
        type: Object,
        required: true,
      },
      expandedTaskId: {
        type: String,
        default: '',
      },
      showSender: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        tierFeatures,
        messages: {
          items: [],
          meta: {
            haveMoreItems: true,
          },
        },
        emails: {
          items: [],
          meta: {
            haveMoreItems: true,
          },
        },
        sms: {
          items: [],
          meta: {
            haveMoreItems: true,
          },
        },
        lastEmail: {},
        isMessageEditMode: false,
        messageToEdit: '',
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      areMessages () {
        return this.activeMessagesGroup === 'messages';
      },
      isSocialFeatureAvailable () {
        return tierFeatures.statuses?.social_automation?.exist &&
          !tierFeatures.statuses?.social_automation?.upgrade;
      },
      areEmails () {
        return this.activeMessagesGroup === 'emails';
      },
      areSms () {
        return this.activeMessagesGroup === 'sms';
      },
      isSmsFeatureAvailable () {
        return tierFeatures.statuses?.sms?.exist &&
          !tierFeatures.statuses?.sms?.upgrade;
      },
      areTasks () {
        return this.activeMessagesGroup === 'tasks';
      },
      isActivity () {
        return this.activeMessagesGroup === 'activity';
      },
      isDeclinedProfile () {
        if (this.profile.connected_at) {
          return false;
        }
        return this.profile.inmail_status !== 'accepted' ||
          this.messages.items.some(m => m.acceptance_status === 'declined');
      },
    },
    watch: {
      'profile._id' () {
        this.init();
      },
      'messages.meta.haveMoreItems' (value) {
        if (!value) {
          this.$emit('update:hasOldMessagesButton', true);
        }
      },
      async hasOldMessagesButton (value) {
        if (!value) {
          await this.getMessagesHandler(true);
        }
      },
      async activeMessagesGroup (newGroup) {
        const shouldScroll = ['messages', 'emails', 'sms'].includes(newGroup);

        if (shouldScroll) {
          // Wait until chat is fully rendered ...
          await nextTick();
          // ... then scroll to end
          this.scrollToEnd();
        }
      },
    },
    created () {
      this.scrollHandlerThrottle = throttle(this.scrollHandler, 400, { leading: true });
    },

    async mounted () {
      await this.init();
      this.scrollToEnd();
    },
    methods: {
      async init () {
        this.messages.items = this.emails.items = [];
        this.messages.meta.haveMoreItems = this.emails.meta.haveMoreItems = true;
        await Promise.all([
          this.getMessagesHandler(),
          this.getEmailsHandler(),
          this.getSmsHandler(),
        ]);
        this.approval = this.profile.approval;
        if (this.approval) {
          this.approval.is_me = true;
          this.messages.items.push(this.approval);
        }
        if (!this.tierFeatures.statuses?.social_automation?.exist) {
          this.$emit('update:activeMessagesGroup', 'emails');
        }
        if (!this.messages.items.length &&
          this.activeMessagesGroup === 'messages' &&
          !this.profile.approve &&
          this.tierFeatures?.email_automation?.exist) {
          this.$emit('update:activeMessagesGroup', 'emails');
        }
        const chat = this.$refs.chat;
        if (this.messages.items.length && chat?.offsetHeight === chat?.scrollHeight) {
          this.messages.meta.haveMoreItems = false;
        }
      },
      async editApproval (isApprove) {
        try {
          const newProfile = await updateApproval(this.profile._id, this.selectedCredential._id, isApprove);
          const newApproval = newProfile.approval;
          newApproval.is_me = true;
          const oldApprovalIndex = this.messages.items.findIndex(a => a.sequence_id);
          const profile = { ...this.profile, ...newProfile };
          this.$emit('update-profile-prop', profile);
          this.messages.items.splice(oldApprovalIndex, 1, newApproval);
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      scrollToEnd (currentScrollHeight = 0) {
        const chat = this.$refs.chat;
        if (!chat?.scrollTop) {
          chat.scrollTop = currentScrollHeight
            ? chat?.scrollHeight - currentScrollHeight
            : chat?.scrollHeight;
        }
      },
      async getDataHandler () {
        switch (this.activeMessagesGroup) {
        case 'messages':
          await this.getMessagesHandler(true);
          break;
        case 'emails':
          await this.getEmailsHandler(true);
          break;
        case 'sms':
          await this.getSmsHandler(true);
          break;
        }
      },
      async scrollHandler () {
        const shouldSearch = this.$refs.chat?.scrollTop < 50 && this[this.activeMessagesGroup]?.meta?.haveMoreItems;
        if (!shouldSearch) {
          return;
        }
        const currentScrollHeight = this.$refs.chat?.scrollHeight;
        this.activeMessagesGroup === 'messages'
          ? await this.getMessagesHandler(true)
          : await this.getEmailsHandler(true);
        await this.getDataHandler();
        this.scrollToEnd(currentScrollHeight);
      },
      async getMessagesHandler (lazyload = false) {
        try {
          this.loaded = false;
          const newMessages = await getMessages(
            this.profile._id,
            this.selectedCredential._id,
            !this.hasOldMessagesButton,
            lazyload ? this.messages.items.length : 0
          );
          this.messages.items = lazyload ? [...newMessages, ...this.messages.items] : newMessages;
          this.messages.meta.haveMoreItems = !!newMessages.length;
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      async getEmailsHandler (lazyload = false) {
        try {
          this.loaded = false;
          const newEmails = await getEmails(
            this.profile._id,
            this.selectedCredential._id,
            lazyload ? this.emails.items.length : 0
          );
          this.emails.items = lazyload ? [...newEmails, ...this.emails.items] : newEmails;
          this.emails.meta.haveMoreItems = !!newEmails.length;
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      async getSmsHandler (lazyload = false) {
        if (this.profile.type === 'email') { return; }
        try {
          this.loaded = false;
          const newSms = await getSms(
            this.profile._id,
            lazyload ? this.sms.items.length : 0
          );
          this.sms.items = lazyload ? [...newSms, ...this.sms.items] : newSms;
          this.sms.meta.haveMoreItems = !!newSms.length;
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      updateMessagesOrEmails (type, item, isDelete = false) {
        if (isDelete) {
          this[type].items = this[type].items.filter(i => i._id !== item._id);
          return;
        }
        this[type].items.push(item);
      },
      replyToEmail (email) {
        this.lastEmail = email;
        this.showBlocks.emailResponserClosable = true;
      },
      sendEmailHandler (email) {
        this.updateMessagesOrEmails('emails', email);
        this.showBlocks.emailResponserClosable = false;
      },
      enterEditMessageMode (message) {
        this.messageToEdit = message;
        this.isMessageEditMode = true;
      },
      async updateApproveMessage (message) {
        if (message.trim() !== this.profile.approve?.approved_text) {
          try {
            await updateTaskApprovedMessage(
              this.profile.approve?.dash_task_id,
              message.trim()
            );
            this.$emit('refetch-info');
          } catch (error) {
            this.$noty.error(error);
          }
        }
        this.exitEditMode();
      },
      exitEditMode () {
        this.messageToEdit = '';
        this.isMessageEditMode = false;
      },
    },
  };
</script>
<style lang="scss">
@import "@/components/inboxPage/chat/inboxChat";
</style>
