/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function getAllWorkflows (credentialId) {
  const data = await useHttp(
    '/workflows/fetch',
    'GET',
    null,
    {
      credentials_id: credentialId,
    }
  );

  return data.payload;
}

/** @returns {base.workflow.WorkflowsPage[]} */
async function getSearchWorkflows (credentialId, offset, limit = 8, query = '', deletedOnly = false, archivedOnly = false) {
  const data = await useHttp(
    '/workflows',
    'GET',
    null,
    {
      credentials_id: credentialId,
      offset,
      limit,
      query,
      deleted_only: deletedOnly,
      archived_only: archivedOnly,
    }
  );

  return data.payload;
}
/* eslint-disable camelcase */

/** @returns {base.workflow.WorkflowSettings} */
async function createWorkflow (workflow) {
  const {
    name,
    searches,
    status,
    sequence,
    credentials,
    is_existing_people,
    is_include_talked_before,
    is_only_premium,
    sequences_nodes,
    sequences_links,
    sequences_type,
    is_link_tracking,
    is_email_only,
    settings,
  } = workflow;
  const data = await useHttp(
    '/workflows/create',
    'POST',

    // { name, searches, sequence, credentials, is_send_connection }
    {
      name,
      status,
      is_existing_people,
      is_include_talked_before,
      is_only_premium,
      is_link_tracking,
      is_email_only,
      searches: JSON.stringify(searches),
      sequence: JSON.stringify(sequence),
      credentials: JSON.stringify(credentials),
      sequences_nodes: JSON.stringify(sequences_nodes),
      sequences_links: JSON.stringify(sequences_links),
      settings: JSON.stringify(settings),
      sequences_type,
    }
  );
  return data.payload;
}

/* eslint-disable camelcase */

/** @returns {base.workflow.WorkflowSettings} */
async function updateWorkflow (workflowId, workflow) {
  const data = await useHttp(
    `/workflows/${workflowId}/update`,
    'POST',
    // { name, searches, sequence, credentials }

    workflow,
    {},
    true
  );
  return data.payload;
}
/**
 *
 * @param {string} workflowId
 * @param {Promise<('active'|'inactive')>} status
 * @returns
 */
async function updateWorkflowStatus (workflowId, status) {
  const data = await useHttp(
    `/workflows/${workflowId}/update_status`,
    'POST',
    { status },
    {},
    true
  );
  return data.payload;
}

async function deleteWorkflow (workflowId) {
  const data = await useHttp(
    `/workflows/${workflowId}/delete_async`,
    'POST'
  );
  return data.payload;
}

async function archiveWorkflow (workflowId) {
  const data = await useHttp(
    `/workflows/${workflowId}/archive`,
    'POST'
  );
  return data.payload;
}

/** @returns {Promise<base.workflow.WorkflowSettings>} */
async function getWorkflow (workflowId, credentialsId) {
  const data = await useHttp(
        `/workflows/${workflowId}`,
        'GET',
        null,
        { credentials_id: credentialsId }
  );
  return data.payload;
}

async function updateWorkflowsOrder (order) {
  const data = await useHttp(
    '/workflows/order',
    'POST',
    {
      workflows_order: JSON.stringify(order),
    }
  );
  return data.payload;
}
/** @type {api.workflow.uploadCSV} */
async function uploadCSV (file, includeExistingPeople, credentialsId) {
  const data = await useHttp(
    '/workflows/upload_csv_file',
    'POST',
    {
      file,
    },
    {
      credentials_id: credentialsId,
      is_existing_people: includeExistingPeople,
    }
  );
  return data.payload;
}

async function setDataCSV (fileName, includeExistingPeople, divoFields, credentialsId, isEmailOnly, saveCsv = false, customFields) {
  const data = await useHttp(
    '/workflows/set_data_from_csv_file',
    'POST',
    {
      filename: fileName,
      is_existing_people: includeExistingPeople,
      user_fields: divoFields,
      credentials_id: credentialsId,
      is_email_only: isEmailOnly,
      save_csv: saveCsv,
      custom_fields: customFields,
    },
    null,
    true
  );
  return data.payload;
}

async function rerunWorkflow (workflowId) {
  const data = await useHttp(
    `/workflows/${workflowId}/rerun_searches`,
    'POST'
  );

  return data.payload;
}

// smart_sequences methods

async function createSmartSequences (nodes, links, workflowId) {
  const data = await useHttp(
    '/workflows/smart_sequences',
    'POST',
    {
      workflow_id: workflowId,
      nodes,
      links,
    }
  );
  return data.payload;
}

async function getSmartSequences (workflowId) {
  const data = await useHttp(
    '/workflows/smart_sequences',
    'GET',
    {
      workflow_id: workflowId,
    }
  );
  return data.payload;
}

async function editSmartSequences (nodes, links, workflowId) {
  const data = await useHttp(
    '/workflows/smart_sequences',
    'POST',
    {
      workflow_id: workflowId,
      nodes,
      links,
    }
  );
  return data.payload;
}

async function sendTestEmail (email, subject, integrationId, message, credentialsId, signatureId = '', workflowId = '') {
  const data = await useHttp(
    '/workflows/steps/send_email/test',
    'POST',
    {
      receiver: email,
      subject,
      integration_id: integrationId,
      text: message,
      credentials_id: credentialsId,
      signature_id: signatureId,
      workflow_id: workflowId,
    },
    null,
    true
  );
  return data.payload;
}

/** @returns {Promise<base.workflow.statistics.Rates>} */
async function getWorkflowRates (workflowId, credentialsId) {
  const data = await useHttp(
    `/workflows/${workflowId}/rates`,
    'GET',
    null,
    { credentials_id: credentialsId },
    true
  );
  return data.payload;
}

async function restoreWorkflow (workflowId) {
  const data = await useHttp(
    `/workflows/${workflowId}/restore`,
    'POST'
  );
  return data.payload;
}

/**
 * @param {string} type
 * @param {string} objective
 * @param {string[]} caseStudies
 * @param {string} credId
 */
async function startMagicCampaignBuild (type, objective, caseStudies, credId) {
  const data = await useHttp(
    '/workflows',
    'POST',
    {
      workflow_to_generate: type,
      objective,
      cases_study: caseStudies,
      credentials_id: credId,
    },
    null,
    true
  );

  return data.payload;
}

export {
  getAllWorkflows,
  createWorkflow,
  updateWorkflow,
  deleteWorkflow,
  archiveWorkflow,
  updateWorkflowStatus,
  uploadCSV,
  updateWorkflowsOrder,
  rerunWorkflow,
  createSmartSequences,
  getSmartSequences,
  editSmartSequences,
  setDataCSV,
  getSearchWorkflows,
  sendTestEmail,
  getWorkflow,
  getWorkflowRates,
  restoreWorkflow,
  startMagicCampaignBuild
};
