import { computed } from 'vue';

export const AiLabelsEnum = Object.freeze({
  INTERESTED: 'interested',
  NOT_INTERESTED: 'not_interested',
  OUT_OF_OFFICE: 'out_of_office',
  MAKING_AN_INTRODUCTION: 'making_an_introduction',
  WRONG_CONTACT: 'wrong_contact',
  FOLLOW_UP_LATER: 'follow_up_later',
  HARD_NO: 'hard_no',
  neutral: 'neutral',
});

// TODO: add translation
export const AiLabelsNames = computed(() => ({
  interested: 'Interested',
  not_interested: 'Not interested',
  out_of_office: 'Out of office',
  making_an_introduction: 'Making an introduction',
  wrong_contact: 'Wrong contact',
  follow_up_later: 'Follow up later',
  hard_no: 'Hard no',
  neutral: 'Neutral',
}));

export const AiLabelsColors = Object.freeze({
  interested: '#96C258',
  not_interested: '#DC3545',
  out_of_office: '#6AA9DC',
  making_an_introduction: '#A26289',
  wrong_contact: '#FFA011',
  follow_up_later: '#3E7496',
  hard_no: '#8B0000',
  neutral: '#343A40',
});
