<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end">
    <validation-observer
      v-slot="{ passes }"
      :key="key"
    >
      <form
        @submit.prevent="passes(saveDeal)"
      >
        <modal
          :key="key"
          name="CrmDealModal"
          width="836px"
          height="auto"
          :adaptive="true"
          @closed="handleClose"
        >
          <div
            ref="vldParent"
            class="modal crm-deal-modal vld-parent"
          >
            <div
              class="crm-deal-modal__close-button"
            >
              <i
                v-if="isEdit"
                class="bx bx-trash"
                @click="deleteDeal"
              />
              <i
                class="bx bx-x"
                @click="handleClose"
              />
            </div>
            <div
              class="crm-deal-modal__main"
              :class="{'mb-3': selectedCrm === 'hubspot'}"
            >
              <div class="crm-deal-modal__header">
                <h2 class="crm-deal-modal__title">
                  {{
                    isEdit
                      ? $t('pipelinesPage.modals.deal.titleEdit')
                      : $t('pipelinesPage.modals.deal.titleCreate')
                  }}
                </h2>
                <span
                  class="crm-deal-modal__status text_xs"
                  :class="{'color_secondary': chosenStatus.value === 'open',
                           'color_danger': chosenStatus.value === 'lost',
                           'color_success': chosenStatus.value === 'won',
                  }"
                > {{ chosenStatus.name }} </span>
              </div>
              <div class="crm-deal-modal__settings">
                <div class="crm-deal-modal__details">
                  <div
                    :key="isKey"
                    class="crm-deal-modal__inputs-block"
                  >
                    <div
                      v-for="input in inputs"
                      :key="input._id"

                      class="crm-deal-modal__input-item"
                      :class="{'crm-deal-modal__input-item_half': input.size === 'half' }"
                    >
                      <default-input
                        v-if="input.input"
                        :value.sync="input.model"
                        :description="input.name"
                        :validation-string="input.validation"
                        :placeholder="input.placeholder"
                        size="xs"
                      />
                      <default-select
                        v-if="!input.input && isInbox && input._id === 'platform'"
                        :items="isEdit ? [] : input.data"
                        max-height="100"
                        :data-closable="input._id"
                        :value="input.chosen.name ? input.chosen.name : ''"
                        :description="input.name"
                        input-size="xs"
                        :input-placeholder="input.placeholder"
                        @select="(item) => {input.chosen = item; selectCrm(item)}"
                      />
                      <default-select
                        v-if="!input.input && input._id !== 'platform'"
                        :items="input.data"
                        max-height="100"
                        :data-closable="input._id"
                        :value="input._id === 'currency' ? input.chosen.code : input.chosen?.name || ''"
                        :description="input.name"
                        input-size="xs"
                        :input-placeholder="input.placeholder"
                        @select="(item) => {input.chosen = item; checkPipeline(input, item)}"
                      />
                      <div
                        v-if="input.data && input._id === 'stage_id' && input?.chosen?.name"
                        class="crm-deal-modal__stage-pagination"
                      >
                        <div
                          v-for="(stageLine, i) in input.data"
                          :key="i"
                          class="crm-deal-modal__stage-line"
                          :class="setActiveStage(i, input)"
                          @click="chooseStage(stageLine, input)"
                        />
                      </div>
                    </div>
                    <div
                      v-if="selectedCrm !== 'hubspot'"
                      class="crm-deal-modal__deal-status"
                    >
                      <span class="text_xs">
                        {{ $tc('common.status') }}
                      </span>
                      <crm-deal-status
                        :chosen-status="chosenStatus.value"
                        @change-status="changeStatus"
                      />
                    </div>
                  </div>
                </div>
                <chosen-prospects
                  class="crm-deal-modal__prospects"
                  :prospects="prospects"
                  :selected-crm="selectedCrm"
                  @add-item="addProspect"
                  @delete-item="deleteItem"
                />
                <chosen-companies
                  class="crm-deal-modal__companies"
                  :companies="companies"
                  :selected-crm="selectedCrm"
                  @add-item="addCompanies"
                  @delete-item="deleteItem"
                />
              </div>
            </div>
            <div
              class="crm-deal-modal__buttons-block mt-2"
              :class="{'mt-3': selectedCrm === 'hubspot'}"
            >
              <a
                v-if="selectedCrm !== 'default'"
                :href="openCrmPlatform"
                target="_blank"
              >
                <default-button
                  v-if="isEdit"
                  size="m"
                  :title="$t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.buttons.openWithService', { service: capitalize(selectedCrm) })"
                  color="neutral"
                  form="rectangle"
                />
              </a>
              <default-button
                size="m"
                :title="$t('actions.cancel')"
                color="neutral"
                form="rectangle"
                @action="handleClose"
              />
              <default-button
                size="m"
                :title="buttonName"
                color="primary"
                type="submit"
                form="rectangle"
              />
            </div>
          </div>
        </modal>
      </form>
    </validation-observer>
  </portal>
</template>

<script>
  import { loaderMixin } from '@/mixins/loaderMixin';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { createDeal, updateDeal, getStages } from '@/api/crmIntegration';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect';
  import { setAltImg } from '@/mixins/setAltImg';
  import { ValidationObserver } from 'vee-validate';
  import { CRM_DEAL_INPUTS } from '@/utils/crm/crmDealInputs';
  import ChosenProspects from '@/modals/CrmModals/ChosenProspects';
  import { capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin';
  import ChosenCompanies from '@/modals/CrmModals/ChosenCompanies';
  import CrmDealStatus from '@/modals/CrmModals/CrmDealStatus';

  export default {
    name: 'CrmDealModal',
    components: {
      CrmDealStatus,
      ChosenCompanies,
      DefaultInput,
      DefaultButton,
      DefaultSelect,
      ValidationObserver,
      ChosenProspects,
    },
    mixins: [loaderMixin, setAltImg, capitalizeFilterMixin],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: '',
      },
      amountValue: {
        type: [String, Number],
        default: 0,
      },
      owners: {
        type: Array,
        default: () => [],
      },
      pipelines: {
        type: Array,
        default: () => [],
      },
      stages: {
        type: Array,
        default: () => [],
      },
      currencies: {
        type: Array,
        default: () => [],
      },
      deal: {
        type: Object,
        default: undefined,
      },
      stage: {
        type: Object,
        default: undefined,
      },
      prospects: {
        type: Array,
        default: () => [],
      },
      companies: {
        type: Array,
        default: () => [],
      },
      selectedPipeline: {
        type: Object,
        default: () => ({}),
      },
      selectedOwner: {
        type: Object,
        default: () => ({}),
      },
      selectedCrm: {
        type: String,
        default: '',
      },
      crms: {
        type: Array,
        default: () => [],
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      isInbox: {
        type: Boolean,
        default: false,
      },
      isRemount: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        key: 'CrmDealModal',
        inputs: [...CRM_DEAL_INPUTS.value],
        oldProspects: [],
        oldCompanies: [],
        chosenStatus: {},
        isKey: false,
      };
    },

    computed: {
      buttonName () {
        if (this.isEdit) {
          return this.$t('actions.save');
        } else return this.$t('actions.create');
      },
      openCrmPlatform () {
        if (this.selectedCrm !== 'default') {
          return this.selectedCrm === 'pipedrive'
            ? `https://${this.deal?.crm_space?.domain}.pipedrive.com/deal/${this.deal.external_id}` || ''
            : `https://app.hubspot.com/contacts/${this.deal?.crm_space?.external_id}/deal/${this.deal.external_id}` || '';
        } else return '';
      },
      dealCurrentMember () {
        return this.prospects[0]?.full_name || this.companies[0]?.name || '';
      },
    },
    watch: {
      show (show) {
        if (show) {
          this.inputs = [...CRM_DEAL_INPUTS.value];
          this.oldProspects = this.prospects;
          this.oldCompanies = this.companies;
          this.deal.status
            ? this.chosenStatus = { name: this.$t(`status.${this.deal.status}`), value: this.deal.status }
            : this.chosenStatus = { name: this.$t('status.open'), value: 'open' };
          if (this.isEdit && this.isInbox) {
            this.$emit('select-crm', this.deal.platform);
          }
          this.addDataToInputs();
          this.addDataToInputsInInbox();
          this.addChosenInputs();
          this.$modal.show('CrmDealModal');
        } else {
          this.$modal.hide('CrmDealModal');
          this.clearInputs();
          this.key = `${this.key} ${new Date().getTime()}`;
        }
      },
      'prospects.length' () {
        if (this.oldProspects !== this.prospects) {
          this.watcherForDealName();
        }
      },
      'companies.length' () {
        if (this.oldCompanies !== this.companies) {
          this.watcherForDealName();
        }
      },
      isRemount () {
        this.isKey = this.isRemount;
        this.addDataToInputsInInbox();
        this.addChosenInputs();
      },
    },
    methods: {
      handleClose () {
        this.$emit('update:show', false);
      },
      addNameToDeal () {
        if (this.inputs?.length) {
          this.inputs = this.inputs.map(input => {
            if (input._id === 'title' && (input.model.includes(this.$t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.dealWith')) || !input.model)) {
              input.model = this.$t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.dealWithService', {
                name: this.dealCurrentMember,
              });
            }
            return input;
          });
        }
      },
      watcherForDealName () {
        if (!this.companies.length && !this.prospects.length) {
          this.inputs = this.inputs.map(input => {
            if (input._id === 'title' && input.model.includes(this.$t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.dealWith'))) {
              input.model = '';
            }
            return input;
          });
        } else this.addNameToDeal();
      },
      async checkPipeline (input, item) {
        try {
          this.loaded = false;
          if (input._id === 'pipeline_id') {
            this.stagesForInput = await getStages(this.selectedCrm, {
              with_deals: false,
              status: '',
              pipeline_id: item._id || '',
              owner_id: '',
              q: '',
            });
            this.inputs.forEach(input => {
              input = Object.assign(input, {});
              if (input._id === 'stage_id') {
                input.data = this.stagesForInput;
                input.chosen = this.stagesForInput.find(e => e.external_id === this.deal?.crm_stage?.external_id) || this.stagesForInput[0];
              }
            });
            this.isKey = !this.isKey;
          }
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      changeStatus (status) {
        this.chosenStatus = { name: this.$t(`status.${status}`), value: status };
      },
      addDataToInputs () {
        this.inputs.forEach(input => {
          input = Object.assign(input, {});
          switch (input._id) {
          case 'owner_id':
            input.data = this.owners;
            input.chosen = !this.selectedOwner?._id ? {} : this.selectedOwner;
            if (this.selectedCrm === 'pipedrive' && !this.selectedOwner?._id) {
              input.chosen = this.owners[0];
            }
            break;
          case 'currency':
            input.data = this.currencies.map(e => {
              e.name = e.code;
              return e;
            });
            input.chosen = this.currencies.find(e => e.code === 'USD') || '';
            break;
          case 'stage_id':
            input.data = this.stages;
            input.chosen = this.stage || {};
            break;
          case 'pipeline_id':
            input.data = this.pipelines;
            input.chosen = this.selectedPipeline || {};
            break;
          case 'title':
            input.model = this.name;
            break;
          case 'probability':
            input.model = this.stage.probability;
            break;
          case 'value':
            input.model = this.amountValue;
            break;
          }
        });
      },
      addChosenInputs () {
        if (this.isEdit) {
          this.inputs.forEach(input => {
            input = Object.assign(input, {});
            switch (input._id) {
            case 'platform':
              if (this.isInbox) {
                input.chosen = this.crms.find(e => e._id === this.deal.platform);
              }
              break;
            case 'owner_id':
              input.chosen = this.owners.find(e => e.external_id === this.deal?.crm_owner?.external_id) || {};
              break;
            case 'currency':
              input.chosen = this.currencies.find(e => e.code === this.deal.currency) || '';
              break;
            case 'pipeline_id':
              input.chosen = this.pipelines.find(e => e._id === this.deal.crm_pipeline) || {};
              if (this.isInbox) {
                this.checkPipeline(input, input.chosen);
              }
              break;
            case 'stage_id':
              if (!this.isInbox) {
                input.chosen = this.stages.find(e => e.external_id === this.deal?.crm_stage?.external_id) || {};
              }
              break;
            case 'title':
              input.model = this.deal.title;
              break;
            case 'probability':
              input.model = this.deal.probability;
              break;
            case 'value':
              input.model = this.deal.value || '';
              break;
            }
          });
        }
      },
      addDataToInputsInInbox () {
        if (this.isInbox) {
          this.inputs.forEach(input => {
            input = Object.assign(input, {});
            switch (input._id) {
            case 'platform':
              input.data = this.crms;
              input.chosen = this.crms.find(e => e._id === this.selectedCrm) || {};
              break;
            case 'owner_id':
              input.data = this.owners;
              input.chosen = this.selectedOwner || {};
              if (this.selectedCrm === 'pipedrive' && !this.selectedOwner?.name) {
                input.chosen = this.owners[0];
              }
              break;
            case 'currency':
              input.data = this.currencies.map(e => {
                e.name = e.code;
                return e;
              });
              input.chosen = this.currencies.find(e => e.code === 'USD') || '';
              break;
            case 'stage_id':
              input.data = this.stages;
              input.chosen = this.stages[0] || {};
              break;
            case 'pipeline_id':
              input.data = this.pipelines;
              input.chosen = this.selectedPipeline || {};
              break;
            case 'title':
              input.model = this.name;
              input.model = this.$t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.dealWithService', {
                name: this.prospects[0].full_name,
              });
              break;
            case 'probability':
              input.model = this.probability;
              break;
            case 'value':
              input.model = this.amountValue;
              break;
            }
          });
        }
      },
      clearInputs () {
        this.inputs.forEach(input => {
          if (input.input) {
            input.model = '';
          } else input.chosen = {};
        });
      },
      setActiveStage (i, input) {
        const index = input.data.findIndex(e => e._id === input?.chosen?._id);
        if (index >= i) {
          return 'crm-deal-modal__stage-line_active';
        } return '';
      },
      chooseStage (stageLine, input) {
        input.chosen = stageLine;
        this.isKey = !this.isKey;
      },
      addProspect () {
        this.$emit('add-item', false);
      },
      addCompanies () {
        this.$emit('add-item', true);
      },
      deleteItem (item, isCompany) {
        this.$emit('delete-item', item, isCompany);
      },
      // changeItem (item, isCompany) {
      //   this.$emit('change-item', item, isCompany);
      // },
      deleteDeal () {
        this.$emit('delete-deal', this.deal);
      },
      selectCrm (crm) {
        this.$emit('select-crm', crm._id);
      },
      async saveDeal () {
        const deal = {};
        this.inputs.forEach(input => {
          if (input.input) {
            if (['integer'].includes(input.validation)) {
              deal[input._id] = Number(input.model || 0);
            } else deal[input._id] = input.model;
          } else if (input._id === 'currency') {
            deal[input._id] = input.chosen.code;
          } else deal[input._id] = input.chosen?.external_id || input.chosen._id;
        });
        deal.crm_profile_ids = this.prospects.filter(e => e.external_id).map(e => e._id) || [];
        deal.profile_ids = this.prospects.filter(e => !e.external_id).map(e => e._id) || [];
        deal.company_ids = this.companies.map(e => e._id) || [];
        deal.status = this.chosenStatus.value;
        deal.old_profile_ids = this.oldProspects.map(e => e._id) || [];
        deal.old_company_ids = this.oldCompanies.map(e => e._id) || [];
        let response;
        if (this.selectedCrm === 'pipedrive' && (!this.prospects.length && !this.companies.length)) {
          this.$noty.error(
            this.$t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.notifications.addProspectOrCompany')
          );
        } else {
          if (this.isEdit) {
            this.$emit('update:show', false);
            response = await updateDeal(this.selectedCrm, this.deal._id, deal);
          } else {
            this.$emit('update:show', false);
            response = await createDeal(this.selectedCrm, deal);
          }
          this.$emit('create-deal', response);
        }
      },
    },
  };
</script>

<style lang="scss">
.crm-deal-modal {
  transition: 0.8s;
  height: 100%;
  position: relative;
  background-color: var(--background-color);
  padding: 24px;
  border-radius: 8px;
  &__close-button {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0;
    margin: 0;
    top: 12px;
    right: 12px;
    border: none;
    outline: none;
    background: none;
    font-size: 24px;
    color: #919399;
    & .bx-x:hover {
      color: var(--primary-cl);
    }
    & .bx-trash:hover {
      color: $cl-danger;
    }
  }
  &__main {
    display: flex;
    flex-flow: column;
    gap: 24px;
  }
  &__header {
    display: flex;
    gap: 8px;
    align-items: flex-end;
  }
  &__settings {
    display: flex;
    gap: 16px;
  }
  &__details, &__prospects, &__companies {
    display: flex;
    flex-flow: column;
    flex: 1;
    gap: 8px;
  }
  &__prospect-position {
    display: flex;
    flex-flow: column;
  }
    &__image {
    border-radius: 100%;
    width: 16px;
    height: 16px;
  }
  &__inputs-block {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    row-gap: 12px;
    column-gap: 4px;
    align-items: flex-end;
  }
  &__input-item {
    width: 100%;
    &_half {
      width: 49%;
    }
  }
  &__buttons-block {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
  }
  &__buttons-deal-status {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
  }
  &__stage-pagination {
    display: flex;
    gap: 1px;
  }
  &__stage-line {
    height: 4px;
    margin-top: 8px;
    flex: 1;
    background: var(--secondary-btn-bg);
    border-radius: 8px;
    cursor: pointer;
      &:hover {
    background: var(--secondary-text-color);
   }
    &_active {
      background: var(--primary-cl);
      &:hover {
        background: var(--primary-cl);
    opacity: 0.7;
   }
    }
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_link {
      color: var(--primary-cl);
      cursor: pointer;
      &:hover {
      opacity: 0.7;
    }
    }
    &_margin-left {
      margin-left: auto;
    }
  }
}
</style>
