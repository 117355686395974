<template>
  <li
    ref="taskCardRef"
    class="inbox-task-card"
    :class="{
      'vld-parent': isCardLoading,
      'inbox-task-card_inactive': isTaskInactive,
    }"
    @click.stop="expandCard"
  >
    <div
      class="inbox-task-card__content"
      :class="{ 'inbox-task-card__content_expanded': isCardExpanded }"
    >
      <div class="flex-row mb-1">
        <i
          class="inbox-task-card__expand-icon bx bx-chevron-right icon_size_16 mr-1"
          :class="{ 'bx-rotate-90': isCardExpanded }"
        />

        <i
          class="bx icon_size_16 mr-1"
          :class="[WorkflowActionsIcons[task.type]]"
        />

        <p class="inbox-task-card__title text_s text_bold">
          {{ task.title || taskTitle }}
        </p>
      </div>

      <div class="inbox-task-card__info-wrapper flex-row flex-gap-12">
        <task-card-info
          v-for="info in TASK_INFO"
          :key="info._id"
          :title="info.title"
          :tooltip-options="info.options"
          :selected-option="selectedInfo[info._id]"
          :is-editable="info.isEditable"
          :max-height="info.maxHeight"
          :selector-component="info.selectorComponent"
          @update:selected-option="updateTaskInfo(info._id, $event)"
        />
      </div>

      <div class="inbox-task-card__description-wrapper">
        <task-card-description
          v-if="task.approved_text || isMessageEditModeActive"
          class="mt-2"
          label="Message"
          :description="approvalText"
          :is-edit-mode="isMessageEditModeActive"
          :is-expanded="isCardExpanded"
          @update:description="saveEditedMessage"
          @update:is-edit-mode="isMessageEditModeActive = $event"
        />

        <task-card-description
          v-if="task.note || isNoteEditModeActive"
          class="mt-2"
          label="Description"
          :description="task.note"
          :is-edit-mode="isNoteEditModeActive"
          :is-expanded="isCardExpanded"
          @update:description="saveEditedNote"
          @update:is-edit-mode="isNoteEditModeActive = $event"
        />
      </div>

      <div
        class="inbox-task-card__description-actions flex-row flex-gap-8"
        :class="{ 'mt-2': isCardExpanded }"
      >
        <default-button
          v-if="isAddMessageButtonVisible"
          :title="$t('dashboardPage.myTasks.task.actions.addMessage')"
          size="m"
          form="rectangle"
          color="transparent"
          title-color="color_tertiary"
          icon-name="bx-plus"
          hovered-background-color="background_hovered_neutral_200"
          class="pl-1"
          @click.native.stop
          @action="() => (isMessageEditModeActive = true)"
        />

        <default-button
          v-if="!task.note && !isNoteEditModeActive"
          :title="$t('dashboardPage.myTasks.task.actions.addDescription')"
          size="m"
          form="rectangle"
          color="transparent"
          title-color="color_tertiary"
          icon-name="bx-plus"
          hovered-background-color="background_hovered_neutral_200"
          class="pl-1"
          @click.native.stop
          @action="() => (isNoteEditModeActive = true)"
        />
      </div>
    </div>

    <div
      class="inbox-task-card__actions"
      :class="{ 'inbox-task-card__actions_expanded mt-2': isCardExpanded }"
    >
      <div class="inbox-task-card__actions-wrapper flex-row flex-gap-8 flex-align-items-start">
        <default-button
          :title="goToButtonOptions.title"
          color="neutral"
          form="rectangle"
          size="m"
          :icon-name="goToButtonOptions.icon"
          @click.native.stop
          @action="goToButtonOptions.action"
        />

        <default-button
          class="margin-left"
          :title="$t('actions.cancel')"
          color="neutral"
          form="rectangle"
          size="m"
          icon-name="bx-x"
          @click.native.stop
          @action="setTaskStatus('canceled')"
        />

        <default-button
          :title="positiveActionTitle"
          color="primary"
          form="rectangle"
          size="m"
          icon-name="bx-check"
          @click.native.stop
          @action="setTaskStatus('done')"
        />
      </div>
    </div>

    <div
      v-if="isTaskInactive"
      class="inbox-task-card__status flex-column text_medium"
    >
      <span
        class="inbox-task-card__status-text flex-row flex-gap-4 text_s"
        :class="taskStatusOptions.color"
      >
        <i
          class="bx icon_size_16"
          :class="taskStatusOptions.icon"
        />
        {{ taskStatusOptions.text }}
      </span>

      <default-button
        v-if="task.type === WorkflowActionsTypesEnum.MANUAL_TASK"
        :title="$t('actions.restore')"
        color="transparent"
        title-color="color_primary"
        form="rectangle"
        size="s"
        :has-paddings="false"
        class="inbox-task-card__restore-btn text_medium"
        @click.stop
        @action="setTaskStatus('new')"
      />
    </div>
  </li>
</template>

<script setup>
  import { ref, computed, defineComponent } from 'vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import TaskCardDescription from '@/components/inboxPage/chat/tasks/TaskCardDescription.vue';
  import TaskCardInfo from '@/components/inboxPage/chat/tasks/TaskCardInfo.vue';
  import TaskCardInfoDatePicker from './TaskCardInfo/TaskCardInfoDatePicker.vue';
  import TaskCardInfoTooltip from './TaskCardInfo/TaskCardInfoTooltip.vue';
  import { useSnack } from '@/lib/useSnack';
  import { useI18n } from '@/i18n';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router/composables';
  import { eventBus } from '@/eventbus';
  import { eventBusEvents } from '@/utils/eventBusEvents';
  import { useLoader, loadingWrapper } from '@/use/loader';
  import { useClosable, ClosableData } from '@/use/useClosable';
  import { PRIORITY, PRIORITY_COLORS } from '@/data/priority';
  import { WorkflowActionsNames, WorkflowActionsTypesEnum, WorkflowActionsIcons } from '@/data/workflowActions';
  import { updateTask, updateTaskApprovedMessage, updateTaskStatus } from '@/api/dashboardMethods';
  import moment from 'moment';

  const props = defineProps({
    task: {
      type: Object,
      default: () => ({}),
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits([
    'udate:task',
    'update:active-messages-group',
    'close-inbox-modal',
  ]);

  const APPROVAL_REQUIRED_TASKS = [
    WorkflowActionsTypesEnum.LI_CONNECT,
    WorkflowActionsTypesEnum.LI_CONNECT_PROFILE,
    WorkflowActionsTypesEnum.LI_CONNECT_BY_EMAIL,
    WorkflowActionsTypesEnum.LI_SEND_MESSAGE,
    WorkflowActionsTypesEnum.LI_SEND_INMAIL,
    WorkflowActionsTypesEnum.SEND_EMAIL,
    WorkflowActionsTypesEnum.SEND_SMS,
  ];

  // #region Composables
  const { $snack } = useSnack();
  const { t, tc } = useI18n();
  const store = useStore();
  const router = useRouter();
  // #endregion

  // #region Elements refs
  const taskCardRef = ref();
  // #endregion

  // #region Loader
  const isCardLoading = ref(false);
  const cardLoader = useLoader({ container: taskCardRef });
  cardLoader.loaderWatcher(isCardLoading);
  // #endregion

  // #region Task title
  const taskTitle = computed(() => {
    switch (props.task.type) {
    case WorkflowActionsTypesEnum.LI_CONNECT:
    case WorkflowActionsTypesEnum.LI_CONNECT_PROFILE:
      return 'Approve Connection Request';
    case WorkflowActionsTypesEnum.LI_CONNECT_BY_EMAIL:
      return 'Approve Connection By Email';
    case WorkflowActionsTypesEnum.LI_SEND_MESSAGE:
      return 'Approve Message';
    case WorkflowActionsTypesEnum.LI_SEND_INMAIL:
      return 'Approve InMail';
    case WorkflowActionsTypesEnum.SEND_EMAIL:
      return 'Approve Email';
    case WorkflowActionsTypesEnum.SEND_SMS:
      return 'Approve SMS';
    default:
      return WorkflowActionsNames.value[props.task.type];
    }
  });
  // #endregion

  // #region Task Actions
  const positiveActionTitle = computed(() => {
    return APPROVAL_REQUIRED_TASKS.includes(props.task.type)
      ? t('actions.approve')
      : t('status.complete');
  });
  // #endregion

  // #region Task Info
  const selectedInfo = computed(() => ({
    priority: {
      name: PRIORITY.value?.[props.task.priority] || PRIORITY.value[100],
      value: props.task.priority || 100,
      icon: {
        name: 'bx-flag',
        color: PRIORITY_COLORS?.[props.task.priority] || PRIORITY_COLORS[100],
      },
    },
    assignee: {
      name: props.task.assignee?.full_name || tc('common.assignee'),
      value: props.task.assignee?._id || null,
      avatar: {
        url: props.task.assignee?.avatar || null,
      },
    },
    created_at: {
      name: moment(props.task.created_at).format('MMMM DD, YYYY'),
      value: props.task.created_at,
    },
    due_date: {
      name: props.task.due_date
        ? moment(props.task.due_date).format('MMMM DD, YYYY')
        : t('notifications.notSpecified'),
      value: props.task.due_date || null,
    },
  }));

  const usersList = computed(() => store.getters['account/account']?.users);

  const TASK_INFO = ref([
    {
      _id: 'priority',
      selectorComponent: defineComponent(TaskCardInfoTooltip),
      title: t('dashboardPage.myTasks.table.header.priority'),
      options: Object.entries(PRIORITY.value).map(([value, name]) => {
        return {
          name,
          value: Number(value),
          icon: {
            name: 'bx-flag',
            color: PRIORITY_COLORS[value],
          },
        };
      }),
      isEditable: true,
    },
    {
      _id: 'assignee',
      selectorComponent: defineComponent(TaskCardInfoTooltip),
      title: t('dashboardPage.myTasks.table.header.assignee'),
      options: usersList.value.map((user) => {
        return {
          name: user.full_name,
          value: user._id,
          avatar: {
            url: user.avatar,
          },
        };
      }),
      isEditable: true,
      maxHeight: '150',
    },
    {
      _id: 'created_at',
      selectorComponent: defineComponent(TaskCardInfoTooltip),
      title: t('dashboardPage.myTasks.table.header.createdDate'),
      isEditable: false,
    },
    {
      _id: 'due_date',
      selectorComponent: defineComponent(TaskCardInfoDatePicker),
      title: t('dashboardPage.myTasks.table.header.dueDate'),
      options: [{
        name: props.task.due_date,
        value: props.task.due_date,
      }],
      isEditable: true,
    },
  ]);

  const _updateTaskInfo = async (type, info) => {
    const response = await updateTask(props.task._id, { [type]: info.value });
    emit('update:task', response);
    $snack.success(t('dashboardPage.myTasks.notifications.taskUpdated'));
    return response;
  };

  const updateTaskInfo = loadingWrapper(_updateTaskInfo, isCardLoading);
  // #endregion

  // #region Description
  const tasksTypesWithoutMessage = [
    WorkflowActionsTypesEnum.MANUAL_TASK,
    WorkflowActionsTypesEnum.PUSH_TO_CRM,
    WorkflowActionsTypesEnum.SEND_WEBHOOK,
    WorkflowActionsTypesEnum.MANUAL_CALL,
    WorkflowActionsTypesEnum.ENRICH_PROFILE,
  ];

  const isAddMessageButtonVisible = computed(() => {
    return !props.task.approved_text &&
      !isMessageEditModeActive.value &&
      !tasksTypesWithoutMessage.includes(props.task.type);
  });

  const approvalText = computed(() => {
    if (WorkflowActionsTypesEnum.SEND_EMAIL === props.task.type) {
      return new DOMParser()
        .parseFromString(props.task.approved_text, 'text/html')
        .querySelector('body').innerText;
    }
    return props.task.approved_text;
  });

  const isMessageEditModeActive = ref(false);
  const isNoteEditModeActive = ref(false);

  const isDescriptionEditModeActive = computed(() => {
    return isMessageEditModeActive.value || isNoteEditModeActive.value;
  });

  const saveEditedMessage = async (message) => {
    const response = await updateTaskMessage(message);
    emit('update:task', response);
    isMessageEditModeActive.value = false;
  };

  const saveEditedNote = async (note) => {
    await updateTaskInfo('note', { value: note });
    isNoteEditModeActive.value = false;
  };

  const _updateTaskMessage = async (message) => {
    const response = await updateTaskApprovedMessage(props.task._id, message);
    emit('update:task', response);
    $snack.success(t('dashboardPage.myTasks.notifications.taskUpdated'));
    return response;
  };

  const updateTaskMessage = loadingWrapper(_updateTaskMessage, isCardLoading);
  // #endregion

  // #region Expand handler
  const isCardExpandable = computed(() => {
    return ['new', 'hold'].includes(props.task.status) &&
      !isDescriptionEditModeActive.value;
  });

  const isCardExpanded = ref(isCardExpandable.value ? props.isExpanded : false);

  const expandCard = () => {
    if (!isCardExpandable.value) return;

    isCardExpanded.value = !isCardExpanded.value;
  };

  useClosable(
    new ClosableData(taskCardRef, null, isCardExpanded),
    () => {
      isMessageEditModeActive.value = false;
      isNoteEditModeActive.value = false;
    }
  );
  // #endregion

  // #region "Go to ..." button
  // TODO: Update cases
  const goToButtonOptions = computed(() => {
    const TYPE = WorkflowActionsTypesEnum;

    switch (props.task.type) {
    case TYPE.MANUAL_CALL:
    case TYPE.SEND_SMS:
      return {
        title: t('actions.openTarget', { target: tc('common.dialer') }),
        icon: 'bx-phone',
        action: () => {
          emit('close-inbox-modal');
          eventBus.$emit(
            eventBusEvents.ADD_NUMBER,
            {
              number: props.task.profile.number || '',
              profile: props.task.profile,
            }
          );
        },
      };

    case TYPE.LI_SEND_MESSAGE:
    case TYPE.SEND_EMAIL:
    case TYPE.LI_SEND_INMAIL:
      return {
        title: t('actions.goToTarget', { target: tc('common.inbox') }),
        icon: 'bx-paper-plane',
        action: () => {
          emit('update:active-messages-group', 'messages');
        },
      };

    default:
      return {
        title: WorkflowActionsNames.value[props.task.type],
        icon: 'bx-paper-plane',
        action: () => {
          router.push({
            path: '/campaign/edit/' + props.task.workflow?._id,
          });
        },
      };
    }
  });
  // #endregion

  // #region Status
  const isTaskInactive = computed(() => {
    return !['new', 'hold'].includes(props.task.status);
  });

  const taskStatusOptions = computed(() => {
    switch (props.task.status) {
    case 'done':
      return {
        text: t('status.complete'),
        color: 'color_success',
        icon: 'bx-check',
      };
    case 'canceled':
      return {
        text: t('status.canceled'),
        color: 'color_tertiary',
        icon: 'bx-x',
      };
    default:
      return {
        text: '',
        color: '',
        icon: '',
      };
    }
  });

  const _setTaskStatus = async (status) => {
    const response = await updateTaskStatus(props.task._id, status);

    emit('update:task', response);

    $snack.success(t('dashboardPage.myTasks.notifications.taskUpdated'));

    isCardExpanded.value = false;

    return response;
  };

  const setTaskStatus = loadingWrapper(_setTaskStatus, isCardLoading);
  // #endregion
</script>

<style lang="scss">
.inbox-task-card {
  min-height: max-content;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(35, 44, 96, 0.12);
  padding: 12px;
  cursor: pointer;

  &_inactive {
    display: grid;
    grid-template-rows: 1fr;
    cursor: default;
    pointer-events: none;

    .inbox-task-card__content {
      grid-area: 1/-1;
      opacity: 0.5;
    }

    &:hover {
      .inbox-task-card {
        &__restore-btn {
          opacity: 1;
        }
      }
    }
  }

  &__expand-icon {
    transition: transform .3s ease-out;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(100px, max-content);
    justify-content: start;
    justify-items: start;
  }

  &__content {
    display: grid;
    grid-template-rows: min-content min-content min-content 0fr;
    transition: grid-template-rows .2s ease-out;

    &_expanded {
      grid-template-rows: min-content min-content min-content 1fr;
    }
  }

  &__description-actions {
    min-height: 0;
    overflow: hidden;
    transition: margin .2s ease-out;
  }

  &__actions {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows .2s ease-out, margin .2s ease-out;

    &_expanded {
      grid-template-rows: 1fr;
    }

    &-wrapper {
      min-height: 0;
    }
  }

  &__status {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-area: 1/-1;
    justify-content: end;
    justify-items: end;

    &-text {
      align-self: start;
    }
  }

  &__restore-btn {
    align-self: end;
    opacity: 0;
    transition: opacity .3s ease-out;
  }
}
</style>
