/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import PreferencesPage from '@/views/preferences/PreferencesPage';
// user begin
import UserBasic from '@/views/preferences/user/UserBasic';
import UserPassword from '@/views/preferences/user/UserPassword';
import UserLanguage from '@/views/preferences/user/UserLanguage';
import UserManualApproval from '@/views/preferences/user/UserManualApproval';
import UserNotifications from '@/views/preferences/user/UserNotifications';
// user end

// workspace begin
import WorkspaceBasic from '@/views/preferences/workspace/WorkspaceBasic';
import WorkspaceUsers from '@/views/preferences/workspace/WorkspaceUsers';
import WorkspaceLabels from '@/views/preferences/workspace/WorkspaceLabels';
import WorkspaceAutoLabels from '@/views/preferences/workspace/WorkspaceAutoLabels';
import WorkspaceAppearance from '@/views/preferences/workspace/WorkspaceAppearance';
// import WorkspaceRemoving from '@/views/preferences/workspace/WorkspaceRemoving';
// workspace end

const USER_ROUTE_PATH = '/preferences/user';
const WORKSPACE_ROUTE_PATH = '/preferences/workspace';

const meta = {
  layout: 'DefaultLayout',
  hasSidebar: true,
};

const userRoutes = [
  {
    path: USER_ROUTE_PATH + '/basic',
    name: 'user-basic',
    component: UserBasic,
    meta,
  },
  {
    path: USER_ROUTE_PATH + '/password',
    name: 'user-password',
    component: UserPassword,
    meta,
  },
  {
    path: USER_ROUTE_PATH + '/language',
    name: 'user-language',
    component: UserLanguage,
    meta,
  },
  {
    path: USER_ROUTE_PATH + '/manual-approval',
    name: 'user-manual-approval',
    component: UserManualApproval,
    meta,
  },
  {
    path: USER_ROUTE_PATH + '/notifications',
    name: 'user-notifications',
    component: UserNotifications,
    meta,
  },
];

const workspaceRoutes = [
  {
    path: WORKSPACE_ROUTE_PATH + '/basic/:id',
    name: 'workspace-basic',
    component: WorkspaceBasic,
    meta,
  },
  {
    path: WORKSPACE_ROUTE_PATH + '/users/:id',
    name: 'workspace-users',
    component: WorkspaceUsers,
    meta,
  },
  {
    path: WORKSPACE_ROUTE_PATH + '/labels/:id',
    name: 'workspace-labels',
    component: WorkspaceLabels,
    meta,
  },
  {
    path: WORKSPACE_ROUTE_PATH + '/auto-labels/:id',
    name: 'workspace-auto-labels',
    component: WorkspaceAutoLabels,
    meta,
  },
  {
    path: WORKSPACE_ROUTE_PATH + '/appearance/:id',
    name: 'workspace-appearance',
    component: WorkspaceAppearance,
    meta,
  },
];

const settings = [
  {
    path: '/preferences',
    name: 'preferences',
    component: PreferencesPage,
    redirect: USER_ROUTE_PATH + '/basic',
    children: [
      ...userRoutes,
      ...workspaceRoutes,
    ],
    meta,
  },
];

export { USER_ROUTE_PATH, WORKSPACE_ROUTE_PATH };
export default settings;
