/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

/**
 * @param {import('@/types/api/dashboard/index').FetchTasksFilters} filters
 * @returns {Promise<{
 *  meta: import('@/types/dashboard/interfaces').TasksMeta,
 *  tasks: import('@/types/dashboard/index').Task[]
 * }>}
 */
async function fetchTasks ({
  page = 0,
  limit = 25,
  sortBy = '',
  status = [],
  taskType = [],
  priority = [],
  assignee = [],
  workflow = [],
  credentials = null,
  startDate = {},
  dueDate = {},
}) {
  const data = await useHttp(
    '/dashboard/tasks',
    'GET',
    null,
    {
      page,
      limit,
      sort_by: sortBy,
      status: JSON.stringify(status || []),
      task_type: JSON.stringify(taskType || []),
      priority: JSON.stringify(priority || []),
      assignee: JSON.stringify(assignee || []),
      workflow: JSON.stringify(workflow || []),
      credentials,
      start_date: JSON.stringify(startDate || {}),
      due_date: JSON.stringify(dueDate || {}),
    }
  );

  return data.payload;
}

/**
 * Update tasks fields
 * @param {Array<string>} taskIds
 * @param {object} params
 * @param {number} [params.priority]
 * @param {string} [params.status]
 * @param {boolean} exclude
 * @param {import('@/types/api/dashboard/index').FetchTasksFilters} tasksFilters
 */
async function updateTasks (
  taskIds,
  params,
  exclude = false,
  {
    page = 0,
    limit = 25,
    sortBy = '',
    status = [],
    taskType = [],
    priority = [],
    assignee = [],
    workflow = [],
    credentials = null,
    startDate = {},
    dueDate = {},
  }
) {
  const data = await useHttp(
    '/dashboard/tasks',
    'PATCH',
    {
      task_ids: taskIds,
      ...params,
      exclude,
    },
    {
      page,
      limit,
      sort_by: sortBy,
      status: JSON.stringify(status || []),
      task_type: JSON.stringify(taskType || []),
      priority: JSON.stringify(priority || []),
      assignee: JSON.stringify(assignee || []),
      workflow: JSON.stringify(workflow || []),
      credentials,
      start_date: JSON.stringify(startDate || {}),
      due_date: JSON.stringify(dueDate || {}),
    },
    true
  );

  return data.payload;
}

/**
 * Update task fields
 * @param {string} taskId
 * @param {object} params
 * @param {string} [params.assignee]
 * @param {number} [params.priority]
 * @param {string} [params.status]
 * @param {string} [params.note]
 */
async function updateTask (taskId, params) {
  const data = await useHttp(
    `/dashboard/tasks/${taskId}`,
    'PATCH',
    { ...params },
    {},
    true
  );

  return data.payload;
}

async function updateTaskStatus (taskId, status) {
  const data = await useHttp(
    `/dashboard/tasks/${taskId}/close`,
    'POST',
    { status },
    {},
    true
  );

  return data.payload;
}

async function updateTaskApprovedMessage (taskId, message) {
  const data = await useHttp(
    `/dashboard/tasks/${taskId}/edit_approve`,
    'POST',
    {
      text: message,
    },
    {},
    true
  );

  return data.payload;
}

async function fetchActivityFeed () {
  const data = await useHttp(
    '/dashboard/activity_feed',
    'GET'
  );

  return data.payload;
}

/**
 * @param {number} offset
 * @param {number} limit
 * @param {string[]} accounts
 * @param {string[]} activityTypes
 * @returns {Promise<import('@/types/api/dashboard/index').FetchActivity>}
 */
async function fetchActivity (
  offset = 0,
  limit = 25,
  accounts,
  activityTypes
) {
  const data = await useHttp(
    '/dashboard/activity',
    'GET',
    null,
    {
      accounts: JSON.stringify(accounts),
      activity_types: JSON.stringify(activityTypes),
      offset,
      limit,
    }
  );

  return data.payload;
}

/**
 * @param {string} userId
 * @param {string} periodStart
 * @param {string} periodEnd
 * @returns {Promise<import('@/types/dashboard').Statistics>}
 */
async function fetchStatisticsByPeriod (
  userId,
  periodStart,
  periodEnd
) {
  const data = await useHttp(
    '/dashboard/statistics_by_period',
    'GET',
    null,
    {
      user: userId,
      period_start: periodStart,
      period_end: periodEnd,
    }
  );

  return data.payload;
}

export {
  fetchTasks,
  updateTasks,
  updateTask,
  updateTaskStatus,
  updateTaskApprovedMessage,
  fetchActivityFeed,
  fetchActivity,
  fetchStatisticsByPeriod
};
